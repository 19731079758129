@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  margin: 0;
  font-family: "Source+Sans+Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tr.ant-table-row.users > td > .ant-table-row-expand-icon {
  display: none !important;
}

.ant-table-bordered .ant-table-thead > tr > th.ant-table-expand-icon-th,
.ant-table-bordered .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell,
.ant-table-bordered .ant-table-tbody > tr.ant-table-expanded-row > td {
  border-right: none !important;
}

.ant-table-expanded-row {
  background: white !important;
}

.ant-modal-header {
  background-color: #f6a559 !important;
}

.ant-transfer-list-header {
  display: none !important;
}

.ant-transfer-list {
  padding-top: 0px !important;
  text-align: left !important;
}

.ant-alert-error {
  border-color: #a23e48 !important;
  background-color: #a23e488a !important;
}
