#no-containers-notice {
  text-align: center;
  padding-top: 15px;
}

#brands-count-table {
  border-collapse: collapse;
  min-width: 90vw;
  background-color: hsl(0, 0%, 90%);
}

#brands-count-table thead tr {
  background-color: hsl(168, 40%, 30%);
  color: hsl(0, 0%, 100%);
  font-weight: bold;
}

#brands-count-table th,
#brands-count-table td {
  padding: 10px 12px;
}

#brands-count-table tbody tr {
  border-bottom: 1px solid hsl(0, 0%, 70%);
}

#brands-count-table tbody tr:last-of-type {
  border-bottom: 3px solid hsl(168, 40%, 30%);
}

td {
  text-align: center;
}

td div.icon-cell {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

td.input-col input {
  width: 100%;
  max-width: 80px;
  text-align: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.brand-actions {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.brand-actions p {
  line-height: 0;
  margin-top: 0.8em;
}
