@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  margin: 0;
  font-family: "Source+Sans+Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tr.ant-table-row.users > td > .ant-table-row-expand-icon {
  display: none !important;
}

.ant-table-bordered .ant-table-thead > tr > th.ant-table-expand-icon-th,
.ant-table-bordered .ant-table-tbody > tr > td.ant-table-row-expand-icon-cell,
.ant-table-bordered .ant-table-tbody > tr.ant-table-expanded-row > td {
  border-right: none !important;
}

.ant-table-expanded-row {
  background: white !important;
}

.ant-modal-header {
  background-color: #f6a559 !important;
}

.ant-transfer-list-header {
  display: none !important;
}

.ant-transfer-list {
  padding-top: 0px !important;
  text-align: left !important;
}

.ant-alert-error {
  border-color: #a23e48 !important;
  background-color: #a23e488a !important;
}

#background {
  min-height: 100vh;
  width: 100vw;
  background-color: hsl(0, 0%, 85%);
  padding: 2vw;
}

#audit-report {
  max-height: 90vh;
  color: hsl(120, 1%, 23%);
  display: flex;
  flex-direction: column;
  font-family: Merriweather;
  font-weight: 500;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: scroll;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

div#venue-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: hsl(168, 40%, 45%);
}

div#venue-information p {
  margin: 0;
}

div#venue-information p.venue-name {
  margin: 15px 10px 10px 10px;
  color: hsl(168, 90%, 85%);
  font-size: medium;
  font-weight: 700;
  letter-spacing: 0.8px;
}

/* div#venue-information p.station-name,
div#venue-information p.shelf-name {
  margin: 5px;
  color: hsl(168, 90%, 85%);
  font-size: small;
  font-weight: 400;
  letter-spacing: 0.8px;
} */

#submit-recount-section {
  position: fixed;
  width: 100%;
  bottom: 2vw;
}

#submit-recount-section button {
  width: 96vw;
  background-color: hsl(168, 40%, 55%);
  letter-spacing: 2px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* taken from  https://fdossena.com/?p=html5cool/buttons/i.frag */
  padding: 0.7em 1.4em;
  margin: 0 0.3em 0.3em 0;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
}

#AlcoholBrandCard {
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu" sans-serif;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#no-containers-notice {
  text-align: center;
  padding-top: 15px;
}

#brands-count-table {
  border-collapse: collapse;
  min-width: 90vw;
  background-color: hsl(0, 0%, 90%);
}

#brands-count-table thead tr {
  background-color: hsl(168, 40%, 30%);
  color: hsl(0, 0%, 100%);
  font-weight: bold;
}

#brands-count-table th,
#brands-count-table td {
  padding: 10px 12px;
}

#brands-count-table tbody tr {
  border-bottom: 1px solid hsl(0, 0%, 70%);
}

#brands-count-table tbody tr:last-of-type {
  border-bottom: 3px solid hsl(168, 40%, 30%);
}

td {
  text-align: center;
}

td div.icon-cell {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

td.input-col input {
  width: 100%;
  max-width: 80px;
  text-align: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.brand-actions {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.brand-actions p {
  line-height: 0;
  margin-top: 0.8em;
}

#information-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-weight: bolder;
  background-color: hsl(168, 40%, 55%);
}

#information-banner p#information-type-category {
  color: hsl(120, 20%, 80%);
  font-size: large;
  letter-spacing: 0.8px;
  margin: 5px;
  border-bottom: 1.5px solid hsl(120, 20%, 80%);
}

#information-banner p#information-brand-name {
  color: hsl(120, 10%, 30%);
  font-size: x-large;
  letter-spacing: 0.5px;
  margin: 10px;
}

#brand-action-icons {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

#brand-special-notes {
  text-align: center;
  display: flex;
  flex-direction: column;
}

#brand-special-notes p {
  line-height: 0;
  margin-top: 1em;
}

