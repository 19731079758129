#information-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-weight: bolder;
  background-color: hsl(168, 40%, 55%);
}

#information-banner p#information-type-category {
  color: hsl(120, 20%, 80%);
  font-size: large;
  letter-spacing: 0.8px;
  margin: 5px;
  border-bottom: 1.5px solid hsl(120, 20%, 80%);
}

#information-banner p#information-brand-name {
  color: hsl(120, 10%, 30%);
  font-size: x-large;
  letter-spacing: 0.5px;
  margin: 10px;
}

#brand-action-icons {
  display: flex;
  gap: 1em;
}

#brand-special-notes {
  text-align: center;
  display: flex;
  flex-direction: column;
}

#brand-special-notes p {
  line-height: 0;
  margin-top: 1em;
}
