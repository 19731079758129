#background {
  min-height: 100vh;
  width: 100vw;
  background-color: hsl(0, 0%, 85%);
  padding: 2vw;
}

#audit-report {
  max-height: 90vh;
  color: hsl(120, 1%, 23%);
  display: flex;
  flex-direction: column;
  font-family: Merriweather;
  font-weight: 500;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: scroll;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

div#venue-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: hsl(168, 40%, 45%);
}

div#venue-information p {
  margin: 0;
}

div#venue-information p.venue-name {
  margin: 15px 10px 10px 10px;
  color: hsl(168, 90%, 85%);
  font-size: medium;
  font-weight: 700;
  letter-spacing: 0.8px;
}

/* div#venue-information p.station-name,
div#venue-information p.shelf-name {
  margin: 5px;
  color: hsl(168, 90%, 85%);
  font-size: small;
  font-weight: 400;
  letter-spacing: 0.8px;
} */

#submit-recount-section {
  position: fixed;
  width: 100%;
  bottom: 2vw;
}

#submit-recount-section button {
  width: 96vw;
  background-color: hsl(168, 40%, 55%);
  letter-spacing: 2px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* taken from  https://fdossena.com/?p=html5cool/buttons/i.frag */
  padding: 0.7em 1.4em;
  margin: 0 0.3em 0.3em 0;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
}
